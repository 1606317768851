import { Chip, Tooltip } from "@mui/material";
import { Pickup } from "types/pickup";

const stateLabel: any = {
  missing_info: "Missing Info",
  pending_confirmation: "Pending Confirmation",
  scheduled: "Scheduled",
  canceled: "Canceled",
  processing: "Processing",
  initial_report_ready: "Initial Report Ready",
  on_hold: "On Hold",
  verifying: "Verifying",
  complete: "Complete",
};

const virtualPickupStateLabel: any = {
  processing: "Open",
  initial_report_ready: "Initial Report Ready",
  on_hold: "On Hold",
  verifying: "Verifying",
  complete: "Complete",
};

const chipStyle: any = {
  missing_info: { backgroundColor: "#FFE0E0", color: "#AD453D" },
  pending_confirmation: { backgroundColor: "#e1e3e1", color: "#414241" },
  scheduled: { backgroundColor: "#DDF3FC", color: "#074E68" },
  canceled: { backgroundColor: "rgba(0, 0, 0, 0.5)", color: "#FFFFFF" },
  processing: { backgroundColor: "#FFF7EB", color: "#ED6C02" },
  initial_report_ready: { backgroundColor: "#EFEDFF", color: "#230C52" },
  on_hold: { backgroundColor: "rgba(255, 214, 0, 0.17)", color: "#C77700" },
  verifying: { backgroundColor: "rgba(255, 214, 0, 0.17)", color: "#C77700" },
  complete: { backgroundColor: "#EDF6ED", color: "#4CAF50" },
};

const virtualPickupChipStyle: any = {
  processing: { backgroundColor: "rgba(0, 245, 245, 0.1)", color: "rgba(0, 50, 50, 0.8)" },
  initial_report_ready: { backgroundColor: "rgba(239, 237, 255, 1)", color: "rgba(35, 12, 82, 1)" },
  on_hold: { backgroundColor: "rgba(255, 214, 0, 0.17)", color: "#C77700" },
  verifying: { backgroundColor: "rgba(255, 214, 0, 0.17)", color: "#C77700" },
  complete: { backgroundColor: "#EDF6ED", color: "#4CAF50" },
};

export function PickupStatus({ pickup }: { pickup: Pickup }) {
  const stateTooltip: any = {
    missing_info:
      "Your pickup is missing some details! Please submit all the necessary information at least 72 hours prior to the scheduled pickup date.",
    pending_confirmation: "Your pickup request is being reviewed by our team! We’ll get back to you shortly.",
    scheduled:
      "Your pickup was successfully scheduled! Please ensure the pickup details are accurate prior to the date.",
    canceled:
      "Your pickup was canceled! If you have any questions or need help scheduling a new pickup, please reach out to us.",
    processing:
      "Your assets are being processed. You can expect an initial report from us within 7-10 business days if all assets have been released from ABM.",
    initial_report_ready: "View your preliminary report which details all hardware we’ve collected for this pickup.",
    on_hold:
      "Your pickup was placed on hold! We found enrolled assets that we need you to take action on before we can continue processing your pickup and generating your reports.",
    verifying: "Revivn is verifying that your devices have been released from Apple Business Manager.",
    complete: "Your pickup was completed! Your final reports and documents can be viewed below.",
  };

  return (
    <Tooltip placement="top" title={stateTooltip[pickup.state]}>
      <Chip
        label={pickup.virtualPickup ? virtualPickupStateLabel[pickup.state] : stateLabel[pickup.state]}
        sx={[pickup.virtualPickup ? virtualPickupChipStyle[pickup.state] : chipStyle[pickup.state]]}
      />
    </Tooltip>
  );
}
