/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  debounce,
  FormControl,
  Link,
  MenuItem,
  Stack,
  Tab,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useCallback, useEffect, useMemo, useState } from "react";
import theme from "lib/theme";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AppSelect, InputBase } from "components/common/Input";
import { useSearchParams } from "react-router-dom";
import useSearchParamsOperations from "hooks/useSearchParamsOperations";
import usePickups from "hooks/pickups/usePickups";
import { formatDate } from "utils/helpers";
import useLocations from "hooks/locations/useLocations";
import useAssets from "hooks/assets/useAssets";
import useAnalytics from "hooks/analytics/useAnalytics";
import { SecurityLocks } from "./SecurityLocks";
import { AssetsGrid } from "./AssetsDataGrid";
import { RetiredAssetModal } from "./RetiredAssetModal";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { MenuProps } from "constants/filterMenuProps";
import useFeatureFlag from "hooks/useFeatureFlag";

const stateFilters = [
  { label: "Available", value: "Available" },
  { label: "Enrolled", value: "Enrolled" },
  { label: "Checked Out", value: "Checked Out" },
  { label: "Pending Pickup", value: "Pending Pickup" },
  { label: "Verifying Release", value: "Verifying Release" },
  { label: "Retired", value: "Retired" },
  { label: "Repurposed", value: "Repurposed" },
  { label: "Recycled", value: "Recycled" },
];

function Assets() {
  const {
    assets,
    setAssets,
    setIsLoading,
    isLoading,
    isError,
    meta,
    refetch,
    setSelectedAssetIds,
    selectedAssets,
    selectedAssetIds,
    bulkUnenroll,
    bulkPendingPickup,
    statuses,
    setSelectAllFlag,
    selectAllFlag,
    getAllSerialsNumbers,
  } = useAssets();
  const [searchParams] = useSearchParams();
  const { pickups, errorMessage, isLoading: filterIsLoading, hasNextPage, incrementCurrentPage } = usePickups(true);
  const { locations } = useLocations();
  const { createAnalyticsEvent } = useAnalytics();
  const [eventTracked, setEventTracked] = useState(false);
  const { replace, deleteFieldInArrayUsingPrefix, replaceInArrayFieldUsingPrefix } = useSearchParamsOperations();
  const [pickupIds, setPickupIds] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("pickup_id,is_any_of"))
      ?.split("pickup_id,is_any_of,")[1]
      ?.split(",") || []
  );
  const [locationId, setLocationId] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("location_id,is"))
      ?.split("location_id,is,")[1] || ""
  );
  const [status, setStatus] = useState(
    searchParams
      .getAll("filter[]")
      .find((p) => p.includes("status,is"))
      ?.split("status,is,")[1] || ""
  );
  const [value, setValue] = useState(
    searchParams.getAll("filter[]").find((p) => p.includes("state,is_any_of,retired"))
      ? "retired"
      : "live,pending_pickup"
  );
  const [securityLocksOpen, setSecurityLocksOpen] = useState(false);
  const [retiredAssetOpen, setRetiredAssetOpen] = useState(false);

  const { enabled: improveAssetDataEnabled } = useFeatureFlag("improve_asset_data");

  const [sentryRef] = useInfiniteScroll({
    loading: filterIsLoading,
    hasNextPage,
    onLoadMore: incrementCurrentPage,
    disabled: !!errorMessage,
    rootMargin: "0px 0px 400px 0px",
  });

  const availableStateFilters = useMemo(() => stateFilters.filter(({ value }) => statuses.includes(value)), [statuses]);

  const handleSearchChange = useCallback(
    debounce((replaceParamsFn: (f: string, v: string) => void, search: string) => {
      replaceParamsFn("q", search);
    }, 200),
    []
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setIsLoading(true);
    deleteFieldInArrayUsingPrefix("filter[]", "status,is_any_of");
  };

  const cleanFilters = useCallback(() => {
    setPickupIds([]);
    setLocationId("");
    setStatus("");
  }, []);

  useEffect(() => {
    replaceInArrayFieldUsingPrefix("filter[]", "state,is_any_of,", `state,is_any_of,${value}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (pickupIds && pickupIds.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "pickup_id,is_any_of", `pickup_id,is_any_of,${pickupIds.join(",")}`);
      if (value !== "retired") setValue("retired");
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "pickup_id,is_any_of");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupIds]);

  useEffect(() => {
    if (locationId && locationId.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "location_id,is", `location_id,is,${locationId}`);
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "location_id,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    if (status && status.length > 0) {
      replaceInArrayFieldUsingPrefix("filter[]", "status,is", `status,is,${status}`);
    } else {
      deleteFieldInArrayUsingPrefix("filter[]", "status,is");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (eventTracked) return;
    setEventTracked(true);
    createAnalyticsEvent("assets_page_visit");
  }, [eventTracked]);

  const gridTabChanged = () => {
    setAssets([]);
  };

  return (
    <div>
      <Box>
        <Breadcrumbs data={[{ label: "Dashboard", to: "/" }, { label: "Assets" }]}></Breadcrumbs>
      </Box>

      <Typography variant="h4" mt={3} fontWeight="bold">
        Assets
      </Typography>

      <Typography variant="body1" mt={1} mb={4}>
        Manage your retired assets all in one place.{" "}
        {improveAssetDataEnabled && (
          <Link href="https://www.revivn.com/asset-list-faq" target="_blank">
            Learn more about our process and reporting.
          </Link>
        )}
      </Typography>

      {improveAssetDataEnabled && (
        <Grid container spacing={4}>
          <Grid xs={12}>
            <Stack
              gap={2}
              alignItems="center"
              display="grid"
              sx={{ gridTemplateColumns: { lg: "auto 1fr minmax(100px, .2fr) minmax(120px, .35fr)", xs: "1fr" } }}
            >
              <Typography>Quick Filters:</Typography>
              <Stack
                gap={2}
                justifyContent="stretch"
                alignItems="center"
                display="grid"
                sx={{ gridTemplateColumns: { md: "repeat(4, 1fr)", xs: "1fr 1fr" } }}
              >
                <FormControl variant="standard" size="small">
                  <AppSelect
                    multiple
                    value={pickupIds}
                    MenuProps={MenuProps}
                    onChange={(e) => setPickupIds(e.target.value as string[])}
                    renderValue={(values) =>
                      values.length === 0
                        ? "Pickup ID"
                        : values.length === 1
                        ? pickups.find(({ id }) => id.toString() === values[0].toString())?.orderNumber
                        : `${values.length} pickups selected`
                    }
                    displayEmpty
                  >
                    {pickups.map((pickup) => (
                      <MenuItem key={pickup.id} value={pickup.id} ref={sentryRef}>
                        {pickup.orderNumber}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </FormControl>
                <FormControl variant="standard" size="small">
                  <AppSelect
                    multiple
                    value={pickupIds}
                    MenuProps={MenuProps}
                    onChange={(e) => setPickupIds(e.target.value as string[])}
                    renderValue={(values) =>
                      values.length === 0
                        ? "Pickup Date"
                        : values.length === 1
                        ? formatDate(
                            pickups.find(({ id }) => id.toString() === values[0].toString())?.scheduledAt || ""
                          )
                        : `${values.length} pickups selected`
                    }
                    displayEmpty
                  >
                    {pickups
                      .filter(({ scheduledAt }) => scheduledAt)
                      .map((pickup) => (
                        <MenuItem key={pickup.id} value={pickup.id} ref={sentryRef}>
                          {formatDate(pickup.scheduledAt)} - {pickup.orderNumber}
                        </MenuItem>
                      ))}
                  </AppSelect>
                </FormControl>
                <FormControl variant="standard">
                  <AppSelect value={locationId} onChange={(e) => setLocationId(e.target.value.toString())} displayEmpty>
                    <MenuItem value="">Location</MenuItem>
                    {locations.map((location) => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </FormControl>
                <FormControl variant="standard">
                  <AppSelect value={status} onChange={(e) => setStatus(e.target.value.toString())} displayEmpty>
                    <MenuItem value="">Status</MenuItem>
                    {availableStateFilters.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </AppSelect>
                </FormControl>
              </Stack>
              <div>
                <Button variant={"outlined"} onClick={cleanFilters} sx={{ width: { xs: "100%", lg: "auto" } }}>
                  Clear All
                </Button>
              </div>
              <InputBase
                placeholder="Search"
                sx={{
                  "&:hover, &:focus": { zIndex: 2 },
                }}
                onChange={(e) => handleSearchChange(replace, e.target.value)}
              ></InputBase>
            </Stack>
          </Grid>
          <Grid xs={12}>
            {selectedAssets && selectedAssets[0] && (
              <>
                <SecurityLocks open={securityLocksOpen} setOpen={setSecurityLocksOpen} bulkUnenroll={bulkUnenroll} />
                <RetiredAssetModal
                  open={retiredAssetOpen}
                  refetch={refetch}
                  setOpen={setRetiredAssetOpen}
                  bulkPendingPickup={bulkPendingPickup}
                />
              </>
            )}

            <AssetsGrid
              fieldsToHide={["assignedTo"]}
              replace={replace}
              assets={assets}
              isLoading={isLoading}
              isError={isError}
              meta={meta}
              refetch={refetch}
              setSelectedAssetIds={setSelectedAssetIds}
              selectedAssetIds={selectedAssetIds}
              assetsTab={value}
              setRetiredAssetOpen={setRetiredAssetOpen}
              setSecurityLocksOpen={setSecurityLocksOpen}
              getAllSerialsNumbers={getAllSerialsNumbers}
            />
          </Grid>
        </Grid>
      )}

      {!improveAssetDataEnabled && (
        <Box
          sx={{ mt: 3, padding: 4, background: "white", borderRadius: 1, border: `1px solid ${theme.palette.divider}` }}
        >
          <Typography variant="h6">Search your assets</Typography>
          <Typography variant="body1">Find the asset you need by searching for keywords or using filters </Typography>

          {/* <Box sx={{ float: "right", position: "relative", top: "-24px" }}>
          <NewAssetButton />
        </Box> */}

          <Box sx={{ mt: 3 }}>
            <Box sx={{ maxWidth: "550px", width: "100%" }}>
              <InputBase
                placeholder="Search"
                sx={{
                  "&:hover, &:focus": { zIndex: 2 },
                }}
                onChange={(e) => handleSearchChange(replace, e.target.value)}
              ></InputBase>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr 1fr 1fr 1fr 1fr auto"
              columnGap="1em"
              marginY={2}
              alignItems="center"
            >
              <Typography width="100%">Quick Filters:</Typography>
              <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
                <AppSelect
                  multiple
                  value={pickupIds}
                  MenuProps={MenuProps}
                  onChange={(e) => setPickupIds(e.target.value as string[])}
                  renderValue={(values) =>
                    values.length === 0
                      ? "Pickup Date"
                      : values.length === 1
                      ? formatDate(pickups.find(({ id }) => id.toString() === values[0].toString())?.scheduledAt || "")
                      : `${values.length} pickups selected`
                  }
                  displayEmpty
                >
                  {pickups
                    .filter(({ scheduledAt }) => scheduledAt)
                    .map((pickup) => (
                      <MenuItem key={pickup.id} value={pickup.id} ref={sentryRef}>
                        {formatDate(pickup.scheduledAt)} - {pickup.orderNumber}
                      </MenuItem>
                    ))}
                </AppSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <AppSelect value={locationId} onChange={(e) => setLocationId(e.target.value.toString())} displayEmpty>
                  <MenuItem value="">Location</MenuItem>
                  {locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  ))}
                </AppSelect>
              </FormControl>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <AppSelect value={status} onChange={(e) => setStatus(e.target.value.toString())} displayEmpty>
                  <MenuItem value="">Status</MenuItem>
                  {availableStateFilters.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </AppSelect>
              </FormControl>
              <Button variant={"outlined"} onClick={cleanFilters}>
                Clear All
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: 3, ".MuiTabPanel-root": { padding: 0 } }}>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    textTransform: "capitalize",
                    ".MuiTabs-flexContainer": {
                      display: "inline-flex",
                      border: "1px solid",
                      borderColor: theme.palette.divider,
                    },
                    ".MuiButtonBase-root": { minWidth: "230px" },
                  }}
                >
                  {/* TODO:Hide under feature flag when/if live assets becomes a pro feature */}
                  {/* <Tab label="live" value="live,pending_pickup" onClick={() => gridTabChanged()} /> */}
                  <Tab
                    label="retired"
                    value="retired"
                    onClick={() => gridTabChanged()}
                    disabled={true}
                    sx={{
                      "&.Mui-disabled": {
                        color: "#0089BD",
                      },
                    }}
                  />
                </TabList>
              </Box>

              {selectedAssets && selectedAssets[0] && (
                <>
                  <SecurityLocks open={securityLocksOpen} setOpen={setSecurityLocksOpen} bulkUnenroll={bulkUnenroll} />
                  <RetiredAssetModal
                    open={retiredAssetOpen}
                    refetch={refetch}
                    setOpen={setRetiredAssetOpen}
                    bulkPendingPickup={bulkPendingPickup}
                  />
                </>
              )}

              <TabPanel value="live,pending_pickup">
                <AssetsGrid
                  fieldsToHide={[
                    "orderNumber",
                    "scheduledAt",
                    "address",
                    "state",
                    "endOfLifeCondition",
                    "quantity",
                    "assetTag",
                  ]}
                  replace={replace}
                  assets={assets}
                  isLoading={isLoading}
                  isError={isError}
                  meta={meta}
                  refetch={refetch}
                  setSelectedAssetIds={setSelectedAssetIds}
                  selectedAssetIds={selectedAssetIds}
                  assetsTab={value}
                  setRetiredAssetOpen={setRetiredAssetOpen}
                  setSecurityLocksOpen={setSecurityLocksOpen}
                  getAllSerialsNumbers={getAllSerialsNumbers}
                />
              </TabPanel>
              <TabPanel value="retired">
                <AssetsGrid
                  fieldsToHide={["assignedTo"]}
                  replace={replace}
                  assets={assets}
                  isLoading={isLoading}
                  isError={isError}
                  meta={meta}
                  refetch={refetch}
                  setSelectedAssetIds={setSelectedAssetIds}
                  selectedAssetIds={selectedAssetIds}
                  setSelectAllFlag={setSelectAllFlag}
                  selectAllFlag={selectAllFlag}
                  assetsTab={value}
                  setRetiredAssetOpen={setRetiredAssetOpen}
                  setSecurityLocksOpen={setSecurityLocksOpen}
                  getAllSerialsNumbers={getAllSerialsNumbers}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default Assets;
